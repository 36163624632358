import React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import PageError from 'pages/PageError/PageError';

export interface Props {
  children: React.ReactNode;
}

export interface State {
  error: Error | undefined;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, _: React.ErrorInfo): void {
    try {
      throw error;
    } catch (e) {
      datadogRum.addError(e);
    }
  }

  public render() {
    if (this.state.error) {
      return <PageError error={this.state.error} />;
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;

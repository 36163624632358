import React, { useEffect, useState } from 'react';

import versions from 'utils/versions';

export const useGetVersions = () => {
  const appVersion = versions.getAppVersion();
  const [apiVersion, setApiVersion] = useState<string>();
  const [binaryVersion, setBinaryVersion] = useState<React.ReactText>();

  useEffect(() => {
    (async () => {
      setBinaryVersion(await versions.getBinaryVersion());
      setApiVersion(await versions.getApiVersion());
    })();
  }, []);

  return {
    appVersion,
    apiVersion,
    binaryVersion,
  };
};

import { datadogRum } from '@datadog/browser-rum';
import decodeJWT from 'jwt-decode';

import { getUrlParam } from 'utils/url';

export const SHARED_CONTENT_TOKEN_KEY = 'sharedContentToken';

const storeSharedContentToken = (token: string): void => sessionStorage.setItem(SHARED_CONTENT_TOKEN_KEY, token);

const checkUrlForSharedContentToken = (): void => {
  const token = getUrlParam('token');
  if (token) {
    storeSharedContentToken(token);
  }
};

export const getSharedContentToken = (): string | undefined => {
  checkUrlForSharedContentToken();
  return sessionStorage.getItem(SHARED_CONTENT_TOKEN_KEY) || undefined;
};

export const clearSharedContentToken = (): void => {
  sessionStorage.removeItem(SHARED_CONTENT_TOKEN_KEY);
};

export const checkAccessFromSharedContentToken = (contentType: string): boolean => {
  const sharedContentToken = getSharedContentToken();
  if (sharedContentToken) {
    try {
      const { content } = decodeJWT<{ content: string }>(sharedContentToken);
      return content?.includes(contentType);
    } catch (e) {
      datadogRum.addError(e);
    }
  }
  return false;
};

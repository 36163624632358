import { RumInitConfiguration } from '@datadog/browser-rum';

const applicationId = process.env.VITE_DATADOG_APPLICATION_ID;
const clientToken = process.env.VITE_DATADOG_CLIENT_TOKEN;
const service = process.env.VITE_DATADOG_SERVICE;
const env = process.env.VITE_DATADOG_ENV;
const version = process.env.VITE_DATADOG_VERSION;

const DatadogConfig: RumInitConfiguration | undefined =
  applicationId && clientToken
    ? {
        applicationId,
        clientToken,
        service,
        env,
        version,
      }
    : undefined;

export default DatadogConfig;

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { ApiFinanceAccountOverview } from 'api/payments/types';
import { ListResponse } from 'core/listResponse';
import { getFriendlyError } from 'utils/error';
import { getFinanceAccounts } from './payments';

export const QUERY_KEY_FINANCE_ACCOUNT_LIST = 'financeAccountsList';

export interface UseFinanceAccountsList {
  data?: ListResponse<ApiFinanceAccountOverview>;
  error?: string;
  isLoading: boolean;
  isFetched: boolean;
}

export function useFinanceAccountsList(enabled: boolean): UseFinanceAccountsList {
  const { data, error, isLoading, isFetched } = useQuery<ListResponse<ApiFinanceAccountOverview>, AxiosError>(
    [QUERY_KEY_FINANCE_ACCOUNT_LIST],
    () => getFinanceAccounts(),
    {
      enabled,
    },
  );

  return {
    data,
    error: getFriendlyError(error, 'finance account'),
    isLoading,
    isFetched,
  };
}

import React from 'react';

import GoogleTagManager from 'components/_tracking/GoogleTagManager/GoogleTagManager';
import Segment from 'components/_tracking/Segment/Segment';

interface Props {
  children: React.ReactNode;
}

const Tracking: React.FC<Props> = ({ children }: Props) => (
  <GoogleTagManager>
    <Segment>{children}</Segment>
  </GoogleTagManager>
);

export default Tracking;

import { createContext, Dispatch, SetStateAction } from 'react';

export interface CurrentPageContext {
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
}

export const currentPageContext = createContext<CurrentPageContext>({
  pageTitle: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPageTitle: () => {},
});

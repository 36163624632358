/**
 * Disable absolute page positioning (used for Chromatic snapshots)
 */

import React, { forwardRef } from 'react';

import { Props as ContentProps } from './Content';

export type Props = Omit<ContentProps, 'staticContent'>;

const StaticContent = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { className, children, onScroll } = props;
  return (
    <>
      <style>{`
      body.backdrop-no-scroll {
        overflow: auto;
      }
      body.sb-show-main.sb-main-padded {
        padding: 0;
      }
      [class^="Page_container_"] {
        position: static;
        min-height: 100vh;
      }
    `}</style>
      <div ref={ref} onScroll={onScroll} className={className}>
        {children}
      </div>
    </>
  );
});
StaticContent.displayName = 'StaticContent';

export default StaticContent;

/**
 * For production builds, Ionic uses capacitor://localhost on iOS and http://localhost on Android
 * Ionic on iOS does not support cookies
 * See workarounds here: https://github.com/ionic-team/capacitor/issues/1433
 *
 * TODO: stop history pushes for button state changes (request service list, checkout, etc.)
 */

import React from 'react';

import { PlatformConfig } from 'config';
import versions from 'utils/versions';

declare global {
  interface Window {
    dataLayer: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
    hostname?: string;
    appName?: string;
    appId?: string;
    appVersion?: string;
  }
}

const GTM_ID = 'GTM-K3WN2DS';

export const gtmEvent = (e: string): void => {
  window.dataLayer.push({ event: e });
};

const initialize = () => {
  /* eslint-disable */
  // prettier-ignore
  // @ts-ignore
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});const f=d.getElementsByTagName(s)[0]; const j=d.createElement(s); const dl=l!='dataLayer'?`&l=${l}`:'';j.async=true;j.src= `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',GTM_ID);
  /* eslint-enable */
};

interface Props {
  children: React.ReactNode;
}

const GoogleTagManager: React.FC<Props> = (props: Props) => {
  const { children } = props;

  React.useEffect(() => {
    (async () => {
      initialize();
      window.hostname = PlatformConfig.hostname;
      if (PlatformConfig.isNative) {
        window.appName = 'Palmetto';
        window.appId = PlatformConfig.appId;
        window.appVersion = await versions.getBinaryVersion();
      }
    })();
  }, []);

  return <>{children}</>;
};

export default GoogleTagManager;

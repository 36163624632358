import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useUser } from 'api/user/useUser';
import { Platform, PlatformConfig, SegmentConfig } from 'config';
import { isImpersonatedUser } from 'utils/auth';
import { urlWithHashedId } from 'utils/tracking';
import { versions } from 'utils/versions';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

interface EventProperties {
  name?: string;
  version?: string;
  platform?: Platform;
  binaryVersion?: string;
  [key: string]: unknown;
}

interface SegmentContextDefaultProperties {
  app: {
    name: string;
    version: string;
  };
}

export interface Props {
  children: React.ReactNode;
}

export type ClickType = 'button' | 'link';

export const segmentReset = (): void => {
  window.analytics.reset();
};

export async function getSegmentContextDefaultProperties(): Promise<SegmentContextDefaultProperties> {
  return {
    app: {
      name: 'helios-app',
      version: await versions.getAppVersion(),
    },
  };
}

export async function getSegmentDefaultProperties(): Promise<EventProperties> {
  return {
    platform: PlatformConfig.platform,
    binaryVersion: await versions.getBinaryVersion(),
    ...(await getSegmentContextDefaultProperties())?.app,
  };
}

export async function trackEvent(eventName: string, properties?: EventProperties): Promise<void> {
  if (isImpersonatedUser()) {
    console.log('Impersonated user, not tracking event:', eventName);
    return;
  }
  return window.analytics?.track(eventName, {
    ...properties,
    ...(await getSegmentDefaultProperties()),
  });
}

export async function trackClick(
  trackingId: string,
  clickType: ClickType,
  properties?: EventProperties,
): Promise<void> {
  if (isImpersonatedUser()) {
    console.log('Impersonated user, not tracking click:', trackingId);
    return;
  }
  return window.analytics?.track('click', {
    trackingId,
    clickType,
    ...properties,
    ...(await getSegmentDefaultProperties()),
  });
}

const Segment: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const { isLoading } = useUser();

  useEffect(() => window.analytics?.load(SegmentConfig.apiKey), []);

  useEffect(() => {
    if (!urlWithHashedId(pathname)) {
      (async () => {
        const properties = await getSegmentDefaultProperties();
        if (!isLoading && !isImpersonatedUser()) {
          window.analytics?.page(properties);
        }
      })();
    }
  }, [pathname, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
};

export default Segment;

export enum ROUTE {
  AUTH0 = '/auth0',
  DASHBOARD = '/dashboard',
  DASHBOARD_ABOUT = '/dashboard/about',
  DEEPLINK = '/deeplink',
  ENERGY_ADVISOR = '/energy-advisor',
  ENERGY_PROFILE = '/energy-profile',
  FINANCE_ACCOUNTS = '/finance-accounts',
  FINANCE_ACCOUNTS_DETAIL = '/finance-accounts/:id',
  FINANCE_ACCOUNTS_UPDATE_PAYMENT_METHOD = '/finance-accounts/:financeAccountId/update-payment-method',
  HOME = '/',
  LEGACY_ACCOUNT = '/account',
  LEGACY_ONBOARDING = '/onboarding',
  LOGOUT = '/logout',
  MOCK = '/mock',
  ONBOARDING = '/onboarding',
  ONBOARDING_ENERGY_BILL = '/onboarding/energy-bill',
  ONBOARDING_SITE_SURVEY = '/onboarding/site-survey',
  ONBOARDING_TITLE_VERIFICATION = '/onboarding/title-verification',
  ONBOARDING_TREE_REMOVAL = '/onboarding/tree-removal',
  PANEL_PLACEMENT = '/panel-placement',
  PASSWORD_RESET = '/password-reset',
  PAYMENT_SETUP = '/payment/setup',
  PHYSICAL_SITE_SURVEY_AVAILABILITY = '/physical-site-survey-availability',
  PROFILE = '/profile',
  PROJECT_DETAIL = '/projects/:id',
  PROPERTIES_BUILDING_ATTRIBUTE_UPDATE = '/properties/:propertyId/building/:buildingAttribute',
  QUESTIONNAIRE = '/questionnaire/:id',
  REFER = '/refer',
  SERVICE = '/service',
  SIGNUP = '/signup',
  SUPPORT = '/support',
  UTILITY_BILL_UPLOAD = '/utility-bill-upload',
  UTILITY_PROVIDER = '/utility-provider',
  UTILITY_RATE_PLAN_ENROLLMENT = '/utility-rate-plan-enrollment',
}

import { convert, Duration, LocalDate, LocalDateTime, LocalTime, nativeJs, Period } from '@js-joda/core';
import { clamp } from 'lodash';
import maxBy from 'lodash/maxBy';

import { EnergyReading, TimePeriod } from 'api/energy/types';
import { LocalizationLanguages } from 'api/user/types';
import { useUser } from 'api/user/useUser';

export const EASY_MINUTES = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55] as const;

type DateTimeFormatOptions = {
  [s: string]: Intl.DateTimeFormatOptions;
};

export type EasyMinute = (typeof EASY_MINUTES)[number];

export type Meridiem = 'AM' | 'PM';

export type TwentyFourHourTime = {
  hour: number;
  minute: EasyMinute;
};

export type TwelveHourTime = TwentyFourHourTime & {
  meridiem: Meridiem;
};

export enum DateLocale {
  English = 'en-us',
  Spanish = 'es-us',
}

// format options for date with time
export const DateTimeFormat: DateTimeFormatOptions = {
  long: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }, // October 30, 2020, 2:54 PM
  shortMonth: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }, // Oct 30, 2020, 2:54 PM
  short: {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }, // 10/30/2020, 2:54 PM
  time: { hour: 'numeric', minute: 'numeric' }, // 2:54 PM
};

// format options for date only
export const DateFormat: DateTimeFormatOptions = {
  weekday: { weekday: 'long' }, // Monday
  monthAndYear: { month: 'long', year: 'numeric' }, // June 2020
  shortMonthAndYear: { month: 'short', year: 'numeric' }, // Jun 2020
  shortDayOfWeek: { weekday: 'short', month: 'numeric', day: 'numeric' }, // Mon 6/23
  shortDayOfMonth: { month: 'short', day: 'numeric', year: 'numeric' }, // Jun 23, 2020
  shortDayOfMonthNoYear: { month: 'short', day: 'numeric' }, // Jun 23
  longDayOfMonth: { month: 'long', day: 'numeric', year: 'numeric' }, // June 23, 2020
  longDayOfMonthNoYear: { month: 'long', day: 'numeric' }, // June 23
  numericShortYear: { month: 'numeric', day: 'numeric', year: '2-digit' }, // 6/23/20
};

// format options for time only
export const TimeFormat: DateTimeFormatOptions = {
  hour: { hour: 'numeric' }, // 5 PM
};

export const parseDate = (date: string | undefined): Date | undefined => (date ? new Date(date) : undefined);

export const useUserLocaleDateFunctions = () => {
  const { user } = useUser();

  const formatDate = (
    date: string | Date,
    format: Intl.DateTimeFormatOptions,
    locale: LocalizationLanguages = user?.languageCode || LocalizationLanguages.English,
  ): string => {
    let formattedDate = new Intl.DateTimeFormat(locale, {
      ...format,
      ...(typeof date === 'string' && date.length === 10 ? { timeZone: 'UTC' } : {}), // set UTC if date is 10 character string
    }).format(new Date(date));

    if (format === DateTimeFormat.long) {
      formattedDate = formattedDate.replace(' at ', ', ');
    }
    return formattedDate;
  };

  const formatLocalDate = (date: LocalDate, format: Intl.DateTimeFormatOptions): string =>
    formatDate(convert(date).toDate(), format);

  /**
   * Prints the time since date in human readable form (i.e. 2 days).
   * @param date
   */
  const formatTimeSince = (date: Date | string): string => {
    const NOW_THRESHOLD_IN_SECONDS = 10;
    const TODAY_AT_THRESHOLD_IN_HOURS = 12;

    const duration = Duration.between(LocalDateTime.from(nativeJs(new Date(date))), LocalDateTime.now());
    const days = duration.toDays();
    const hours = duration.toHours();
    const minutes = duration.toMinutes();
    const seconds = minutes / 60;

    if (days > 6) {
      return formatDate(date, DateFormat.longDayOfMonth);
    }

    if (days > 1) {
      return formatDate(date, DateFormat.weekday);
    }

    if (days === 1) {
      return `yesterday`;
    }

    if (hours > TODAY_AT_THRESHOLD_IN_HOURS) {
      return `today at ${formatDate(date, DateTimeFormat.time)}`;
    }

    if (hours > 0) {
      return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
    }

    if (minutes > 0) {
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
    }

    if (seconds > NOW_THRESHOLD_IN_SECONDS) {
      return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`;
    }

    return `just now`;
  };

  return { formatDate, formatLocalDate, formatTimeSince };
};

const padZero = (num: number): string => {
  return `0${num}`.slice(-2);
};

export const getYearString = (timestamp: string): string => timestamp.slice(0, 0 + 4);
export const getYearMonthString = (timestamp: string): string => timestamp.slice(0, 0 + 7);
export const getYearMonthDayString = (timestamp: string): string => timestamp.slice(0, 0 + 10);
export const getMonthString = (timestamp: string): string => timestamp.slice(5, 5 + 2);
export const getDayString = (timestamp: string): string => timestamp.slice(8, 8 + 2);
export const getHourString = (timestamp: string): string => timestamp.slice(11, 11 + 2);

export const getDaysInMonth = (timestamp: string): number => {
  const year = parseInt(getYearString(timestamp), 10);
  const month = parseInt(getMonthString(timestamp), 10);
  return new Date(year, month, 0).getDate();
};

export const padHoursForDay = (readings: EnergyReading[]): EnergyReading[] => {
  const lastReading: EnergyReading | undefined = maxBy(readings, 'date');
  if (lastReading) {
    const lastHour: number = parseInt(getHourString(lastReading.date), 10);
    const yearMonthDayString: string = getYearMonthDayString(lastReading.date);
    const padding: EnergyReading[] = Array(23 - lastHour)
      .fill(undefined)
      .map((_, i) => ({
        value: 0,
        date: `${yearMonthDayString}T${padZero(lastHour + i + 1)}:00`,
      }));
    return [...readings, ...padding];
  }
  return readings;
};

export const padDaysForMonth = (readings: EnergyReading[]): EnergyReading[] => {
  const lastReading: EnergyReading | undefined = maxBy(readings, 'date');
  if (lastReading) {
    const lastDay: number = parseInt(getDayString(lastReading.date), 10);
    const yearMonthString: string = getYearMonthString(lastReading.date);
    const daysInMonth: number = getDaysInMonth(lastReading.date);
    const padding: EnergyReading[] = Array(daysInMonth - lastDay)
      .fill(undefined)
      .map((_, i) => ({
        value: 0,
        date: `${yearMonthString}-${padZero(lastDay + i + 1)}`,
      }));
    return [...readings, ...padding];
  }
  return readings;
};

export const currentYear = new Date().getFullYear();
export const getFirstOfMonth = (date: LocalDate): LocalDate => date.withDayOfMonth(1);
export const getLastOfMonth = (date: LocalDate): LocalDate => date.plusMonths(1).withDayOfMonth(1).minusDays(1);

export const getDateRangeForTimePeriod = (date: LocalDate, period: TimePeriod): [LocalDate, LocalDate] => {
  switch (period) {
    case TimePeriod.month:
      return [getFirstOfMonth(date), getLastOfMonth(date)];
    case TimePeriod.week:
      return [date.minusDays(6), date];
    default:
      // TimePeriod.day
      return [date, date];
  }
};

export const getTimestampsForTimePeriod = (date: LocalDate, period: TimePeriod): string[] => {
  // hours in day
  if (period === TimePeriod.day) {
    return Array(24)
      .fill(null)
      .map((_, hour) => LocalDateTime.of(date, LocalTime.MIN.plusHours(hour)).toString());
  }

  // days in week or month
  const [start, end] = getDateRangeForTimePeriod(date, period);
  return Array(Period.between(start, end).days() + 1)
    .fill(null)
    .map((_, day) => start.plusDays(day).toString());
};

export const isInDateRange = (date: LocalDate, start: LocalDate, end: LocalDate): boolean =>
  date >= start && date <= end;

export const isInTimeRange = (time: LocalTime, start: LocalTime, end: LocalTime): boolean =>
  time >= start && time <= end;

export const isFutureTimestamp = (timestamp?: string): boolean => {
  if (timestamp?.length === 16) {
    return LocalDateTime.parse(timestamp).isAfter(LocalDateTime.now());
  }
  if (timestamp?.length === 10) {
    return LocalDate.parse(timestamp).isAfter(LocalDate.now());
  }
  return false;
};

/**
 * Returns true if the passed in local date is for a month in past.
 * @param date
 */
export const isMonthInThePast = (date: LocalDate): boolean => {
  const now = LocalDate.now();
  return date < now && !(date.year() === now.year() && date.monthValue() === now.monthValue());
};

export const timestampToLocalDate = (timestamp: string): LocalDate => LocalDate.parse(timestamp.substr(0, 10));

export const dateToLocalDate = (date: Date): LocalDate => LocalDate.from(nativeJs(date));

export const dateFromLocalDate = (date: LocalDate): Date => convert(date).toDate();

export const dateIsToday = (date: Date | string): boolean => {
  return LocalDate.from(nativeJs(new Date(date))).equals(LocalDate.now());
};

export const dateIsYesterday = (date: Date | string): boolean => {
  const yesterday = LocalDate.now().minusDays(1);
  const providedDate = LocalDate.from(nativeJs(new Date(date)));

  return yesterday.equals(providedDate);
};

export const getJanuaryFirstOfCurrentYear = (): LocalDateTime => {
  const currentYear = new Date().getFullYear();
  const januaryFirst = LocalDateTime.of(currentYear, 1, 1, 0, 0);

  return januaryFirst;
};

export const getSplitDate = (dateString: string): { month: string | undefined; dayAndYear: string } => {
  const dateArray = dateString.split(' ');
  const month = dateArray.shift();
  const dayAndYear = dateArray.join(' ');
  return { month, dayAndYear };
};

/**
 * Converts '2024-02-23' to '2024/02/23' which prevents new Date() from being TZ-aware when parsing.
 * Use this (only) when attempting to strip away any timezone data from a date string and parse it as-is.
 *
 * @param dateString - An ISO date string or simplified date string like '2024-02-23'
 * @returns - A date string like '2024/02/23' that can be passed to the Date constructor
 */
export const dateStringAsSimpleCalendarDay = (dateString = ''): string => {
  return dateString.replaceAll('-', '/').replace(/[TZ].*/, '');
};

export const getClosestEasyMinute = (decimal: number): EasyMinute => {
  const trueMinute = 60 * decimal;
  return [...EASY_MINUTES].sort((a, b) => Math.abs(a - trueMinute) - Math.abs(b - trueMinute))[0];
};

export const clamp24 = (val: number) => clamp(0, val, 24);

const convert24HourTo12Hour = (t: TwentyFourHourTime): TwelveHourTime => {
  const hourTwentyFour = clamp24(t.hour);
  if (hourTwentyFour === 0 || hourTwentyFour === 24) {
    return {
      hour: 12,
      minute: t.minute,
      meridiem: 'AM',
    };
  }
  const meridiem = hourTwentyFour < 12 ? 'AM' : 'PM';
  const hour = hourTwentyFour >= 13 ? hourTwentyFour - 12 : hourTwentyFour;
  return { hour, minute: t.minute, meridiem };
};

export const generate24HourClockTimes = (timesInMinutes: number[]): TwentyFourHourTime[] => {
  const timesDecimal = timesInMinutes.map((t) => t / 60);

  const clockTimes = timesDecimal.map((t): TwentyFourHourTime => {
    const hour = Math.floor(t);
    const minutesDecimal = t - hour;
    const minute = getClosestEasyMinute(minutesDecimal);
    return { hour, minute };
  });
  return clockTimes;
};

export const generate12HourClockTimes = (timesInMinutes: number[]): TwelveHourTime[] => {
  const clockTimesTwentyFour = generate24HourClockTimes(timesInMinutes);
  return clockTimesTwentyFour.map((t) => convert24HourTo12Hour(t));
};

export const timeToString = (t: TwentyFourHourTime, format: '12hr' | '24hr' = '12hr') => {
  const prependZero = (n: number) => (n < 10 ? `0${t.minute}` : t.minute);

  const minutes = prependZero(t.minute);
  const hourTwentyFour = clamp24(t.hour);
  if (format === '24hr') {
    return `${hourTwentyFour}:${minutes}`;
  }
  const twelveHour = convert24HourTo12Hour(t);
  return `${twelveHour.hour}:${minutes} ${twelveHour.meridiem}`;
};

export const areTimesEqual = (a: TwentyFourHourTime, b: TwentyFourHourTime) => {
  return a.hour === b.hour && a.minute === b.minute;
};

export const areDaysEqual = (a: Date, b: Date) => {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
};

export const convertDateAndTime = (d: Date, t: TwentyFourHourTime): Date => {
  const date = new Date(d);
  date.setHours(t.hour);
  date.setMinutes(t.minute);
  return date;
};

const holidayList = [
  { date: '01/01', name: "New Year's Day" },
  { date: '01/15', name: 'Martin Luther King, Jr. Day' },
  { date: '02/19', name: "President's Day" },
  { date: '05/27', name: 'Memorial Day' },
  { date: '06/19', name: 'Juneteenth' },
  { date: '07/04', name: 'Independence Day' },
  { date: '09/02', name: 'Labor Day' },
  { date: '10/14', name: "Indigenous People's Day" },
  { date: '11/11', name: 'Veterans Day' },
  { date: '11/28', name: 'Thanksgiving Day' },
  { date: '11/29', name: 'Day After Thanksgiving' },
  { date: '12/24', name: 'Christmas Eve' },
  { date: '12/25', name: 'Christmas Day' },
];

export const getPalmettoHolidays = () => {
  const year = new Date().getFullYear();
  return holidayList.map((holiday) => {
    const [month, day] = holiday.date.split('/');
    return {
      date: new Date(year, parseInt(month, 10) - 1, parseInt(day, 10)).toISOString().slice(0, 10),
      name: holiday.name,
    };
  });
};

export const daysFromToday = (date: string): number => {
  const dateObj = new Date(`${date}${!date.includes('T') ? `T00:00:00` : ''}`);
  const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
  return Math.floor((dateObj.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
};

import request from 'api/index';
import { PaymentMethod } from 'api/payments/types';
import { BankAccountValidationResponse, UpdateUser, User } from 'api/user/types';
import { UtilityRateFlowLoginResponse, UtilityRatePlanScope } from 'api/utilityRatePlan/types';

export async function getUser(): Promise<User> {
  const result = await request({ url: '/user' });
  return result.data;
}

export async function putUser(user: UpdateUser): Promise<User> {
  const result = await request({ method: 'put', url: '/user', data: user });
  return result.data;
}

export async function getBankAccountValidationResult(requestId?: string): Promise<BankAccountValidationResponse> {
  const result = await request({ url: `/user/bank-account-validation-results/${requestId}` });
  return result.data;
}

export async function getSabalPaymentMethod(): Promise<PaymentMethod> {
  const result = await request({ url: `/user/sabal-payment-method` });
  return result.data;
}

export async function getUtilityRateFlowLogin(scope: UtilityRatePlanScope): Promise<UtilityRateFlowLoginResponse> {
  const result = await request({ method: 'post', url: `/user/light-energy-flow-link`, data: { scope } });
  return result.data;
}

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

const useDebug = () => {
  const flags = useFlags();
  // eslint-disable-next-line no-console
  return useCallback((...args) => (flags.debug ? console.log(...args) : null), [flags.debug]);
};

export default useDebug;

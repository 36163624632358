import { Card as PalmettoCard, CardProps as PalmettoCardProps } from '@palmetto/palmetto-components';
import classNames from 'classnames';
import React, { AnchorHTMLAttributes, useEffect, useRef, useState } from 'react';

import { useDimensions } from 'hooks/useDimensions';
import { RemoveIndex } from 'utils/types';

import styles from './Card.module.scss';

type CleanCardProps = Omit<RemoveIndex<PalmettoCardProps>, 'subdued' | 'background'> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export interface CardProps extends CleanCardProps {
  variant?: 'selected' | 'error' | 'inverted';
  semiTransparentBg?: boolean;
  defaultCorners?: boolean;
}

const Card: React.FC<CardProps> = (props: CardProps) => {
  const thresholdHeight = 110; // More than a "floating row" and less than the shortest feed item height
  const cardRef = useRef<HTMLDivElement>(null);
  const { height } = useDimensions(cardRef, 100);
  const [isThresholdSize, setIsThresholdHeight] = useState(false);
  const { className, children, variant, semiTransparentBg, defaultCorners, ...restProps } = props;

  useEffect(() => {
    if (height > thresholdHeight && !defaultCorners) {
      setIsThresholdHeight(true);
    } else {
      setIsThresholdHeight(false);
    }
  }, [height, defaultCorners]);

  return (
    <PalmettoCard
      ref={cardRef}
      className={classNames(styles.card, className, {
        [styles.selected]: variant === 'selected',
        [styles.error]: variant === 'error',
        [styles.inverted]: variant === 'inverted',
        [styles.semiTransparentBg]: semiTransparentBg,
        [styles.overrideCorners]: isThresholdSize,
      })}
      {...restProps}
    >
      {children}
    </PalmettoCard>
  );
};

export default Card;

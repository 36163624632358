import request from 'api/index';
import { PlatformConfig } from 'config';

import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { ListResponse } from 'core/listResponse';
import { getFileExtensionFromUrl } from 'utils/getFileExtensionFromUrl';
import { readFileAsDataUrl } from 'utils/readFileAsDataUrl';
import { ApiFinanceAccountOverview, ApiFinanceAccountUnionResponse } from './types';

export const FINANCE_ACCOUNT_ENDPOINT = '/finance-accounts';

export async function getFinanceAccount(financeAccountId: string): Promise<ApiFinanceAccountUnionResponse> {
  const result = await request({ url: `${FINANCE_ACCOUNT_ENDPOINT}/${financeAccountId}` });
  return result.data;
}

export async function getFinanceAccounts(): Promise<ListResponse<ApiFinanceAccountOverview>> {
  const result = await request({ url: `${FINANCE_ACCOUNT_ENDPOINT}` });
  return result.data;
}

export async function openStatementDocument(financeAccountId: string, statementLink: string, date: string) {
  const result = await request({
    url: `${FINANCE_ACCOUNT_ENDPOINT}/${financeAccountId}/statement-pdfs?targetUrl=${statementLink}`,
    responseType: 'blob',
  });
  const blob = new Blob([result.data], { type: result.headers['content-type'] || 'application/pdf' });

  if (!PlatformConfig.isNative) {
    const documentUrl = URL.createObjectURL(blob);
    window.open(documentUrl, '_blank');
    return;
  }

  const data = await readFileAsDataUrl(blob);
  if (!data) {
    return;
  }

  const fileName = `statement-${date.split('T')[0]}.${getFileExtensionFromUrl(statementLink, 'pdf')}`;
  const writeFileResult = await Filesystem.writeFile({
    path: fileName,
    data: data.toString(),
    directory: Directory.Cache,
  });

  await FileOpener.openFile({ path: writeFileResult.uri });
}

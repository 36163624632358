import { Element, JSXMapSerializer } from '@prismicio/react';
import { camelCase } from 'lodash';
import React from 'react';
import { remark } from 'remark';
import gfm from 'remark-gfm';
import html from 'remark-html';

const propsWithUniqueKey = (props: Record<string, unknown>, key: string) => {
  return Object.assign(props || {}, { key });
};

export const prismicComponentMap: JSXMapSerializer = {
  [Element.paragraph]: ({ node: element, key }) => {
    // filter out estimator widgets
    if (element?.text.startsWith('<-- ESTIMATOR_WIDGET')) {
      return React.createElement('span');
    }
    return null;
  },
  [Element.preformatted]: ({ key, node: element }) => {
    const markdown = markdownToHtml(element.text);
    const props = {
      dangerouslySetInnerHTML: { __html: markdown || element.text },
      className: 'prismic-preformatted',
    };
    return React.createElement('div', propsWithUniqueKey(props, key));
  },
  [Element.heading2]: ({ node: element, key }) => {
    const id = camelCase(element.text);
    return React.createElement('h2', propsWithUniqueKey({ id }, key), element.text);
  },
};

export function markdownToHtml(markdown: string): string {
  const result = remark().use(gfm).use(html).processSync(markdown);
  return result.toString();
}

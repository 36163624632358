import React from 'react';

import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import Page from 'components/Page/Page';
import { ROUTE } from 'routes';

import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import styles from './PageNotFound.module.scss';

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  const localePrefix = 'pageNotFound';
  return (
    <Page layout="singleItem">
      <Card maxWidth="4xl" padding="lg" className={styles.pageNotFound}>
        <h1>{t(`${localePrefix}.header`)}</h1>
        <p>{t(`${localePrefix}.body`)}</p>
        <Button to={ROUTE.HOME} trackingId="page-not-found-return-home">
          {t(`${localePrefix}.returnToHome`)}
        </Button>
      </Card>
    </Page>
  );
};

export default PageNotFound;

import { datadogRum, RumEvent, RumEventDomainContext } from '@datadog/browser-rum';
import React from 'react';
import { useLocation } from 'react-router';

import { useUser } from 'api/user/useUser';
import { PlatformConfig } from 'config';
import DatadogConfig from 'config/datadogConfig';
import { useGetVersions } from 'hooks/useGetVersions';

const IGNORED_ERRORS = ['ResizeObserver loop limit exceeded', 'Script error.'];

const beforeSend = (event: RumEvent, context: RumEventDomainContext) =>
  !(event.type === 'error' && IGNORED_ERRORS.includes(event.error.message));

if (DatadogConfig && !PlatformConfig.isTestRun) {
  datadogRum.init({
    ...DatadogConfig,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackViewsManually: true,
    trackUserInteractions: true,
    allowFallbackToLocalStorage: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [{ match: PlatformConfig.baseApiUrl, propagatorTypes: ['tracecontext'] }],
    beforeSend,
  });
  datadogRum.startSessionReplayRecording();
}

const useDatadog = (): void => {
  const { appVersion, apiVersion, binaryVersion } = useGetVersions();
  const { user } = useUser();
  const { pathname } = useLocation();

  const platformContext = React.useMemo(
    () => ({
      apiVersion,
      appVersion,
      binaryVersion,
      platform: PlatformConfig.platform,
    }),
    [apiVersion, appVersion, binaryVersion],
  );
  datadogRum.setGlobalContextProperty('platformContext', platformContext);

  const userContext = React.useMemo(() => {
    if (user?.auth0Id) {
      return {
        id: user._id,
        auth0Id: user.auth0Id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        source: user.source,
      };
    }
    return undefined;
  }, [user?.auth0Id, user?.firstName, user?.lastName, user?.email, user?._id, user?.source]);

  if (userContext) {
    datadogRum.setUser(userContext);
  } else {
    datadogRum.clearUser();
  }

  React.useEffect(() => {
    datadogRum.startView({
      name: pathname,
    });
  }, [pathname]);
};

export default useDatadog;

import request from '..';

const ENDPOINT = '/customerTerms';

export const termsAndConditionsName = 'termsandconditions';

export interface CustomerTerms {
  _id: string;
  version: number;
  termName: string;
  createdAt: string;
  updateDescription: string;
  content: string;
}

export async function validateTermAgreement(termName: string): Promise<boolean> {
  const { data } = await request({ url: `${ENDPOINT}/agreements/${termName}/validate` });
  return data;
}

export async function getCustomerTerms(termName: string): Promise<CustomerTerms> {
  const { data } = await request({ url: `${ENDPOINT}/documents/${termName}` });
  return data;
}

export async function postTermsAgreement(termId: string): Promise<void> {
  await request({ method: 'post', url: `${ENDPOINT}/agreements/${termId}` });
}

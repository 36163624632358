import { useEffect } from 'react';

const useAmplitudeExperiment = () => {
  useEffect(() => {
    const amplitudeAPIKey = process.env.VITE_NEXT_PUBLIC_AMPLITUDE_API_KEY;

    if (!amplitudeAPIKey) {
      console.error('Amplitude API Key not found in environment variables!');
      return;
    }

    const script = document.createElement('script');
    script.src = `https://cdn.amplitude.com/script/${amplitudeAPIKey}.experiment.js?integration=segment`;
    script.id = 'amplitude-experiment-script';

    script.onerror = () => {
      console.error('Failed to load the Amplitude experiment script.');
    };

    document.head.appendChild(script);

    // eslint-disable-next-line consistent-return
    return () => {
      const existingScript = document.getElementById('amplitude-experiment-script');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);
};

export default useAmplitudeExperiment;

import { Browser } from '@capacitor/browser';
import { RouterDirection } from '@ionic/core';
import { useHistory } from 'react-router';

import { PlatformConfig } from 'config';
import { baseUrl } from 'utils/url';

export const IN_APP_BROWSER_FLAG = 'inappbrowser';

type Target = '_self' | '_blank';

interface UseNavigation {
  openURL: (url: string, direction?: RouterDirection) => void;
  openInApp: (url: string, target?: Target) => void;
  goBack: () => void;
  isInAppBrowser: () => boolean;
}

const addInAppBrowserFlag = (urlString: string): string => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    url = new URL(`${baseUrl}${urlString}`); // If no domain, add app base url
  }
  url.searchParams.set(IN_APP_BROWSER_FLAG, 'true');
  return url.toString();
};

const hasInAppBrowserFlag = (appUrl: URL): boolean => {
  const { search } = appUrl;
  const params = new URLSearchParams(search);
  return params.get(IN_APP_BROWSER_FLAG) === 'true';
};

export const useNavigation = (): UseNavigation => {
  const history = useHistory();

  return {
    openURL: (url: string) => {
      if (url.indexOf('/') === 0) {
        // internal link
        history.push(url);
      } else if (url.indexOf('mailto:') === 0 || url.indexOf('tel:') === 0) {
        // email or phone
        window.location.href = url;
      } else {
        // external link
        Browser.open({ url }).then();
      }
    },
    openInApp: (url: string, target?: Target) => {
      if (PlatformConfig.isNative) {
        Browser.open({ url: addInAppBrowserFlag(url) });
      } else {
        window.open(url, target || '_blank');
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    goBack: history ? history.goBack : () => {},
    isInAppBrowser: () => hasInAppBrowserFlag(new URL(window.location.href)),
  };
};

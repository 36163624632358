import React, { forwardRef } from 'react';

import StaticContent from './StaticContent';

export interface Props {
  className?: string;
  children?: React.ReactNode;
  staticContent?: boolean;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
}

const Content = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { className, children, staticContent, onScroll } = props;
  if (staticContent) {
    return (
      <StaticContent ref={ref} onScroll={onScroll} className={className}>
        {children}
      </StaticContent>
    );
  }
  return (
    <main ref={ref} onScroll={onScroll} className={className} style={{ zIndex: 1 }}>
      {children}
    </main>
  );
});
Content.displayName = 'Content';

export default Content;

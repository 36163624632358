import { App } from '@capacitor/app';

import { getVersion } from 'api/version/version';
import pkg from '../../package.json';

import { datadogRum } from '@datadog/browser-rum';
import { PlatformConfig } from '../config';

export const versions = {
  getAppVersion: (): string => {
    return pkg.version;
  },
  getApiVersion: (): Promise<string | undefined> => {
    try {
      return getVersion();
    } catch (e) {
      datadogRum.addError(e);
      return Promise.resolve(undefined);
    }
  },
  getBinaryVersion: async (): Promise<string | undefined> => {
    if (PlatformConfig.isNative) {
      try {
        return (await App.getInfo()).version;
      } catch (e) {
        datadogRum.addError(e);
        return Promise.resolve(undefined);
      }
    }
    return Promise.resolve(undefined);
  },
};

export default versions;

import { Box, Icon } from '@palmetto/palmetto-components';
import { RTHeading2Node } from '@prismicio/client';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { camelCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { prismicComponentMap } from 'utils/prismic';
import styles from './ArticleModal.module.scss';

export interface ArticleModalProps {
  uid: string;
  isOpen: boolean;
  onDismiss: () => void;
  imageUrl?: string;
}

const formatIndex = (index: number) => {
  return `${index < 9 ? '0' : ''}${index + 1} `;
};

const ArticleModal: React.FC<ArticleModalProps> = (props: ArticleModalProps) => {
  const { uid, isOpen, onDismiss, imageUrl } = props;
  const [headers, setHeaders] = useState([]);
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);
  const [prismicContent, { state: prismicFetchState }] = usePrismicDocumentByUID('blog_post', uid);

  useEffect(() => {
    if (prismicFetchState === 'failed') {
      onDismiss();
    }
    if (prismicContent) {
      setHeaders(
        prismicContent.data.body
          .filter((node: RTHeading2Node) => node.type === 'heading2' && 'text' in node)
          .map((node: RTHeading2Node) => ('text' in node ? node.text : '')),
      );
    }
  }, [prismicContent, uid, prismicFetchState, onDismiss]);

  const getStickyHeaderHeight = () => {
    const stickyContainerHeight = document.getElementById(`${uid}-sticky-header`)?.clientHeight || 0;
    if (stickyContainerHeight > 0) {
      setStickyHeaderHeight(stickyContainerHeight);
    }
  };

  useEffect(() => {
    getStickyHeaderHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, headers]);

  useResizeObserver(() => {
    getStickyHeaderHeight();
  }, 100);

  const closeButton = (
    <Button trackingId={`article-modal-${uid}-close`} isNaked className={styles.closeButton} onClick={onDismiss}>
      <Icon name="remove-light" />
    </Button>
  );

  return (
    <Modal
      id={`article-modal-${uid}`}
      className={styles.articleModal}
      isOpen={isOpen}
      fullScreenMobile
      onDismiss={onDismiss}
    >
      {prismicContent && (
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        <Box style={{ '--sticky-header-height': `${stickyHeaderHeight}px` } as React.CSSProperties}>
          <div className={styles.imageArticleHeader}>
            {closeButton}
            <img
              src={imageUrl || prismicContent.data.image.url}
              alt={prismicContent.data.image.alt}
              className={styles.image}
            />
          </div>

          <div id={`${uid}-sticky-header`} className={styles.textArticleHeader}>
            {closeButton}
            <h1 className={styles.articleTitle}>{prismicContent.data.title}</h1>
          </div>

          <div className={styles.mainContent}>
            <div className={styles.sideBar}>
              <div className={styles.sideBarContent}>
                {headers.map((header, index) => {
                  const headerId = camelCase(header);
                  return (
                    <Button
                      key={headerId}
                      onClick={() => {
                        const element = document.getElementById(headerId);
                        if (element) {
                          element.scrollIntoView({ behavior: 'smooth' });
                        }
                      }}
                      variant="text"
                      trackingId={headerId}
                    >
                      <span>{formatIndex(index)}</span>
                      {header}
                    </Button>
                  );
                })}
              </div>
            </div>
            <div className={styles.articleContent}>
              <h1 className={styles.articleTitle}>{prismicContent.data.title}</h1>
              <PrismicRichText field={prismicContent.data.body} components={prismicComponentMap} />
            </div>
          </div>
        </Box>
      )}
    </Modal>
  );
};

export default ArticleModal;

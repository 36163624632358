import React from 'react';

import { useUser } from 'api/user/useUser';
import Button from 'components/Button/Button';
import Message from 'components/Message/Message';

import styles from './ImpersonationBanner.module.scss';

export interface ImpersonationBannerProps {
  forceImpersonationBanner: boolean;
}

const ImpersonationBanner: React.FC<ImpersonationBannerProps> = (props: ImpersonationBannerProps) => {
  const { forceImpersonationBanner } = props;

  const { isImpersonatedUser, stopImpersonating, user } = useUser();

  const endImpersonation = () => {
    stopImpersonating();
    window.location.reload();
  };

  if (!isImpersonatedUser() && !forceImpersonationBanner) {
    return null;
  }

  const impersonatedUserName: string | undefined =
    (isImpersonatedUser() || forceImpersonationBanner) && user ? `${user.firstName} ${user.lastName}` : undefined;
  return (
    <Message color="yellow" hasIcon className={styles.impersonating}>
      <span className={styles.impersonatedUserName}>Impersonating {impersonatedUserName}</span>
      <Button onClick={endImpersonation} size="sm" trackingId="impersonation-stop">
        Stop
      </Button>
    </Message>
  );
};

export default ImpersonationBanner;

export const getUrlParam = (param: string): string | null => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return params.get(param);
};

export const addUrlParam = (url: string, param: string): string => {
  return url.includes('?') ? `${url}&${param}` : `${url}?${param}`;
};

export const baseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

import { Icon } from '@palmetto/palmetto-components';
import React, { useEffect, useState } from 'react';

import Logo from 'assets/android-icon.png';

import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import styles from './SmartBannerAndroid.module.scss';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

interface Props {
  showSmartBanner?: boolean;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const SmartBannerAndroid: React.FC<Props> = (props: Props) => {
  const { showSmartBanner } = props;
  const [show, setShow] = useState(false || showSmartBanner);
  const [dismissed, setDismiss] = useState(false);
  const { t } = useTranslation();
  const localePrefix = 'smartBannerAndroid';

  const [deferredPromptEvent, setDeferedPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);

  // Open banner when beforeinstallprompt event is fired (Android only)
  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent): void => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferedPromptEvent(e);

      const isAndroid = navigator?.userAgent?.match(/Android/i);
      setShow(Boolean(isAndroid));
    };

    const cleanup = (): void => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };

    if (dismissed) {
      cleanup();
    } else {
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }

    return cleanup;
  }, [dismissed]);

  const clickHandler = (): void => {
    deferredPromptEvent?.prompt();
    deferredPromptEvent?.userChoice.then(() => {
      setShow(false);
      setDismiss(true);
      setDeferedPromptEvent(null);
    });
  };

  const setShowFalse = (): void => {
    setDismiss(true);
    setShow(false);
  };

  return show ? (
    <div className={styles.banner}>
      <button type="button" onClick={setShowFalse} className={styles.sbclose} aria-label="close">
        <Icon name="remove-light" />
      </button>
      <div
        onClick={clickHandler}
        onKeyPress={clickHandler}
        role="button"
        tabIndex={0}
        className={styles.bannerInner}
      >
        <img className={styles.icon} src={Logo} alt="logo" />
        <div className={styles.textbox}>
          <div className={styles.appname}>{t(`${localePrefix}.appName`)}</div>
          <div>{t(`${localePrefix}.businessName`)}</div>
          <div>{t(`${localePrefix}.cta`)}</div>
        </div>
        <div className={styles.viewtext}>{t(`${localePrefix}.view`)}</div>
      </div>
    </div>
  ) : null;
};

export default SmartBannerAndroid;

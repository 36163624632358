import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const useArticleModal = () => {
  const history = useHistory();
  const [articleModalState, setArticleModalState] = useState<{ isOpen: boolean; uid: string | null }>({
    isOpen: false,
    uid: null,
  });

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const uid = params.get('article');

    if (uid) {
      setArticleModalState({ isOpen: true, uid });
      history.replace({ search: '' });
    }
  }, [history, history.location.search]);

  const dismiss = () => {
    setArticleModalState({ isOpen: false, uid: null });
  };

  return {
    articleModalState,
    dismiss,
  };
};

import { LDFlags } from 'config';
import { isValidStateCode, USStateCode } from 'utils/stateNames';

export type UtilityProvider = {
  name: string;
  providerId: string;
  states: USStateCode[];
};

export type UtilityQuery = {
  stateCode?: USStateCode;
  flags?: LDFlags;
};

export const getEnabledUtilityProviders = (flags?: LDFlags): UtilityProvider[] => {
  const arcadiaProviders = validateUtilityProviders(flags?.arcadiaEnabledUtilityProviders);
  return arcadiaProviders ?? DEFAULT_UTILITY_PROVIDERS;
};

export const getUtilitiesForState = ({ stateCode, flags }: UtilityQuery): UtilityProvider[] => {
  if (!stateCode) {
    return [];
  }
  return getEnabledUtilityProviders(flags).filter((p) => p.states.includes(stateCode));
};

export const doesStateHaveUtility = (query: UtilityQuery) => {
  return Boolean(getUtilitiesForState(query).length);
};

const validateUtilityProviders = (flagData: unknown): UtilityProvider[] | null => {
  if (!flagData || !Array.isArray(flagData)) {
    return null;
  }

  // allow passing an empty array in LD to completely disable the feature
  if (!flagData.length) {
    return flagData;
  }

  const isValidString = (s: string) => Boolean(s) && typeof s === 'string';

  const validEntries: UtilityProvider[] = flagData.filter((e) => {
    if (!e || typeof e !== 'object' || Array.isArray(e)) {
      return false;
    }
    const { name, providerId, states } = e;
    if (!isValidString(name) || !isValidString(providerId)) {
      return false;
    }
    if (!states || !Array.isArray(states) || !states.length) {
      return false;
    }
    const validStates = states.filter(isValidStateCode);
    return Boolean(validStates.length);
  });

  return validEntries.length ? validEntries : null;
};

const DEFAULT_UTILITY_PROVIDERS: UtilityProvider[] = [
  {
    name: 'Appalachian Power (AEP Appalachian)',
    providerId: 'prv_1e8283e3-a32a-d187-b337-0a283f4b8548',
    states: ['VA'],
  },
  {
    name: 'Arizona Public Service (APS)',
    providerId: 'prv_36d92df7-ea0a-11e0-b6bb-12313d2b6294',
    states: ['AZ'],
  },
  {
    name: 'Atlantic City Electric (ACE)',
    providerId: 'prv_36d9550b-ea0a-11e0-b6bb-12313d2b6294',
    states: ['NJ'],
  },
  {
    name: 'Baltimore Gas & Electric (BGE)',
    providerId: 'prv_36d9a332-ea0a-11e0-b6bb-12313d2b6294',
    states: ['MD'],
  },
  {
    name: 'Commonwealth Edison (ComEd)',
    providerId: 'prv_36dc3aa3-ea0a-11e0-b6bb-12313d2b6294',
    states: ['IL'],
  },
  {
    name: 'Consolidated Edison Co-NY (ConEd)',
    providerId: 'prv_36dc3aa2-ea0a-11e0-b6bb-12313d2b6294',
    states: ['NY'],
  },
  {
    name: 'Consumers Energy',
    providerId: 'prv_36dc88c9-ea0a-11e0-b6bb-12313d2b6294',
    states: ['MI', 'IA'],
  },
  {
    name: 'Delmarva Power',
    providerId: 'prv_f4d47e72-438a-11e1-9d91-12313d2baea4',
    states: ['DE'],
  },
  {
    name: 'Duke Energy',
    providerId: 'prv_36dcfe0b-ea0a-11e0-b6bb-12313d2b6294',
    states: ['NC', 'SC', 'FL', 'IN', 'OH', 'KY'],
  },
  {
    name: 'DTE Electric',
    providerId: 'prv_36dcafe0-ea0a-11e0-b6bb-12313d2b6294',
    states: ['MI'],
  },
  {
    name: 'Entergy',
    providerId: 'prv_36dd734a-ea0a-11e0-b6bb-12313d2b6294',
    states: ['LA', 'AR', 'MS', 'TN'],
  },
  {
    name: 'Evergy',
    providerId: 'prv_1e9fa6b2-bb6d-ddb4-b915-0e95e51793c4',
    states: ['KS', 'MO'],
  },
  {
    name: 'Florida Power & Light (FPL)',
    providerId: 'prv_36dd734d-ea0a-11e0-b6bb-12313d2b6294',
    states: ['FL'],
  },
  {
    name: 'Georgia Power',
    providerId: 'prv_36dde88a-ea0a-11e0-b6bb-12313d2b6294',
    states: ['GA'],
  },
  {
    name: 'Ohio Power (AEP Ohio)',
    providerId: 'prv_36d906e0-ea0a-11e0-b6bb-12313d2b6294',
    states: ['OH'],
  },
  {
    name: 'Pacific Gas & Electric (PG&E)',
    providerId: 'prv_36e14462-ea0a-11e0-b6bb-12313d2b6294',
    states: ['CA'],
  },
  {
    name: 'Pacific Power',
    providerId: 'prv_36e14463-ea0a-11e0-b6bb-12313d2b6294',
    states: ['OR', 'WA', 'CA'],
  },
  {
    name: 'PECO Energy',
    providerId: 'prv_36e14460-ea0a-11e0-b6bb-12313d2b6294',
    states: ['PA'],
  },
  {
    name: 'Potomac Electric Power Co (PEPCO)',
    providerId: 'prv_5c01f071-243e-11e3-a178-22000aa40d1f',
    states: ['MD'],
  },
  {
    name: 'PPL Electric Utilities',
    providerId: 'prv_36e14461-ea0a-11e0-b6bb-12313d2b6294',
    states: ['PA'],
  },
  {
    name: 'Public Service Co of Oklahoma (AEP Oklahoma)',
    providerId: 'prv_36d906e1-ea0a-11e0-b6bb-12313d2b6294',
    states: ['OK'],
  },
  {
    name: 'Puget Sound Energy (PSE)',
    providerId: 'prv_36e1e0b4-ea0a-11e0-b6bb-12313d2b6294',
    states: ['WA'],
  },
  {
    name: 'Rocky Mountain Power',
    providerId: 'prv_36eb7daf-ea0a-11e0-b6bb-12313d2b6294',
    states: ['UT', 'ID', 'WY'],
  },
  {
    name: 'Sacramento Municipal Util Dist (SMUD)',
    providerId: 'prv_36eb7db3-ea0a-11e0-b6bb-12313d2b6294',
    states: ['CA'],
  },
  {
    name: 'Salt River Project (SRP)',
    providerId: 'prv_36eba4c6-ea0a-11e0-b6bb-12313d2b6294',
    states: ['AZ'],
  },
  {
    name: 'San Diego Gas & Electric Co (SDG&E)',
    providerId: 'prv_36eb7db1-ea0a-11e0-b6bb-12313d2b6294',
    states: ['CA'],
  },
  {
    name: 'Southern California Edison Co (SCE)',
    providerId: 'prv_36eb7db0-ea0a-11e0-b6bb-12313d2b6294',
    states: ['CA'],
  },
  {
    name: 'Tucson Electric',
    providerId: 'prv_36ecdc72-ea0a-11e0-b6bb-12313d2b6294',
    states: ['AZ'],
  },
  {
    name: 'Xcel Energy',
    providerId: 'prv_36edc6f1-ea0a-11e0-b6bb-12313d2b6294',
    states: ['WI', 'CO', 'MN', 'NM', 'ND', 'SD', 'MI'],
  },
];

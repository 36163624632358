import { Icon } from '@palmetto/palmetto-components';
import { IconName } from '@palmetto/palmetto-components/src/types';
import classNames from 'classnames';
import React from 'react';

import Button from '../Button/Button';
import Link, { LinkProps } from '../Link/Link';

import styles from './OptionButton.module.scss';

export interface Props {
  children: React.ReactNode;
  className?: string;
  trackingId: string;
  to?: string;
  onClick?: () => void;
  hideChevron?: boolean;
  forceChevron?: boolean;
  actionIcon?: IconName;
  actionSvg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  buttonText?: string;
  tall?: boolean;
  value?: React.ReactNode;
  checked?: boolean;
  warning?: boolean;
  error?: boolean;
  iconName?: IconName;
  target?: LinkProps['target'];
  testId?: string;
}

const OptionButton: React.FC<Props> = (props: Props) => {
  const {
    children,
    className,
    trackingId,
    to,
    onClick,
    hideChevron,
    forceChevron,
    actionIcon,
    actionSvg,
    buttonText,
    tall,
    value,
    checked,
    warning,
    error,
    iconName,
    target,
    testId,
  } = props;
  const classes = classNames(styles.option, { [styles.tall]: tall }, className);
  const chevron = (() => {
    if (hideChevron || (!to && !onClick && !forceChevron)) {
      return null;
    }
    const Svg = actionSvg;
    return Svg ? (
      <Svg className={styles.chevron} />
    ) : (
      <div className={styles.chevronContainer}>
        <Icon name={actionIcon || 'caret-right'} className={styles.chevron} size="sm" />
      </div>
    );
  })();
  const inner = (
    <>
      <span className={styles.label}>
        {(() => {
          if (error) {
            return <Icon className={classNames(styles.icon, styles.danger)} name="c-remove" size="lg" />;
          }
          if (warning) {
            return <Icon className={classNames(styles.icon, styles.warning)} name="t-warning" size="lg" />;
          }
          if (checked) {
            return <Icon className={classNames(styles.icon, styles.success)} name="c-check" size="lg" />;
          }
          if (iconName) {
            return <Icon className={styles.icon} name={iconName} size="lg" />;
          }
          return null;
        })()}
        {children}
      </span>
      {(() => {
        if (buttonText) {
          // TODO: better way of nesting a Palmetto Button inside a Clickable
          return (
            <Button as="a" size="sm" trackingId={trackingId}>
              {buttonText}
            </Button>
          );
        }
        if (value) {
          return (
            <span className={styles.value}>
              {value}
              {chevron}
            </span>
          );
        }
        return chevron;
      })()}
    </>
  );

  if (to) {
    return (
      <Link className={classes} to={to} target={target} trackingId={trackingId} data-testid={testId}>
        {inner}
      </Link>
    );
  }

  if (onClick) {
    return (
      <Button isNaked className={classes} onClick={onClick} trackingId={trackingId} data-testid={testId}>
        {inner}
      </Button>
    );
  }

  return (
    <div className={classes} data-testid={testId}>
      {inner}
    </div>
  );
};

export default OptionButton;

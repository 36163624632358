import classNames from 'classnames';
import React, { lazy, Suspense } from 'react';

import LoadingContent from 'components/LoadingContent/LoadingContent';

import styles from './Logo.module.scss';

const PalmettoLogoSvg = lazy(() => import('./PalmettoLogo'));
const PalmettoLogoMonochromeSvg = lazy(() => import('./PalmettoLogoMonochrome'));
const PalmettoTextLogoSvg = lazy(() => import('./PalmettoTextLogo'));

interface LogoProps {
  className?: string;
  placement?: 'body' | 'header' | 'headerLeft';
  width?: string;
}

const palmettoLogos = {
  body: PalmettoLogoSvg,
  header: PalmettoTextLogoSvg,
  headerLeft: PalmettoLogoMonochromeSvg,
};

const Logo: React.FC<LogoProps> = ({ className, placement = 'body', width }) => {
  const logoClasses = classNames(styles.logo, className, {
    [styles.body]: !width && placement === 'body',
  });

  const CurrentLogo = palmettoLogos[placement];

  return (
    <Suspense fallback={<LoadingContent />}>
      <CurrentLogo style={{ width }} className={logoClasses} />
    </Suspense>
  );
};

export default Logo;

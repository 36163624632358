import { useEffect, useMemo } from 'react';

import { useThemeContext } from 'components/_app/ThemeProvider/ThemeProvider';

const useThemedFavicon = (): void => {
  const { theme } = useThemeContext();
  const originalLinkTags = useMemo(() => document.head.querySelectorAll('.palmetto-favicon'), []);

  useEffect(() => {
    if (document.head.querySelectorAll('.palmetto-favicon').length > 0) {
      // The Palmetto icons are already present, nothing to update.
      return;
    }

    // Remove any existing icons
    document.head.querySelectorAll('[rel$=icon]').forEach((node) => {
      document.head.removeChild(node);
    });

    originalLinkTags.forEach((node) => document.head.append(node));
  }, [theme.id]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useThemedFavicon;

import decodeJWT from 'jwt-decode';
import React, {
  createContext,
  createRef,
  LazyExoticComponent,
  MutableRefObject,
  useContext,
  useEffect,
  useState,
} from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useTheme } from '@palmetto/palmetto-components';
import { useUser } from 'api/user/useUser';
import { PlatformConfig } from 'config';
import { useReactRouterQuery } from 'hooks/useReactRouterQuery';

const PalmettoLightTheme = React.lazy(() => import('components/_app/ThemeProvider/themes/PalmettoLightTheme'));
const PalmettoDarkTheme = React.lazy(() => import('components/_app/ThemeProvider/themes/PalmettoDarkTheme'));

const DARK_MODE_MEDIA_QUERY = window.matchMedia('(prefers-color-scheme: dark)');
export const DARK_MODE_SUFFIX = '-dark';
const THEME_ROOT_SELECTOR = 'body.app';

export enum ThemeId {
  palmetto = 'palmetto',
  palmettoDark = 'palmetto-dark',
}

export interface Theme {
  id: ThemeId;
  cssClass: string;
  cssComponent: LazyExoticComponent<React.FC>;
  companyName: string;
}

export const palmettoTheme: Theme = {
  id: ThemeId.palmetto,
  cssClass: 'light',
  cssComponent: PalmettoLightTheme,
  companyName: 'Palmetto',
};

export const palmettoDarkTheme: Theme = {
  ...palmettoTheme,
  id: ThemeId.palmettoDark,
  cssClass: 'dark',
  cssComponent: PalmettoDarkTheme,
};

export const Themes = {
  palmetto: palmettoTheme,
  palmettoDark: palmettoDarkTheme,
};

export interface ThemeContextShape {
  theme: Theme;
  themeContainerRef: MutableRefObject<HTMLElement | null>;
  isLoading: boolean;
}

export const ThemeContext = createContext<ThemeContextShape>({
  theme: palmettoTheme,
  themeContainerRef: createRef(),
  isLoading: true,
});

ThemeContext.displayName = 'ThemeContext';

export interface ThemeProviderProps {
  children?: React.ReactNode;
  theme?: Theme;
}

export const getThemeFromId = (themeId: ThemeId | string | null | undefined): Theme | undefined => {
  if (!themeId) {
    return undefined;
  }
  return Object.values(Themes).find((t) => t.id === themeId);
};

export const determineTheme = (params: {
  darkMode: boolean;
  propsThemeId: ThemeId | undefined;
  userSource: string | undefined;
  sharedContentUserSource: string | undefined;
}): Theme => {
  const { propsThemeId, darkMode } = params;

  if (!PlatformConfig.isNative) {
    const propsTheme = getThemeFromId(propsThemeId);
    if (propsTheme) {
      return propsTheme;
    }
  }

  return darkMode ? Themes.palmettoDark : Themes.palmetto;
};

const ThemeProvider: React.FC<ThemeProviderProps> = (props: ThemeProviderProps) => {
  const { setTheme: setPalmettoComponentsTheme } = useTheme();
  const { children = null, theme: propsTheme } = props;
  const propsThemeId = propsTheme?.id;

  const { user, isAuthenticated } = useUser();

  const [isLoading, setIsLoading] = React.useState(true);

  // initialize theme container ref and trigger re-render when it's updated
  const themeContainerRef = React.useRef<HTMLElement | null>(null);
  const onThemeContainerRefChange = React.useCallback((node) => {
    if (node) {
      themeContainerRef.current = node;
      setIsLoading(false);
    }
  }, []);

  const query = useReactRouterQuery();

  const { source: userSource } = user || {};

  // look for userSource in sharedContent token
  const sharedContentToken = query.get('token');
  let decodedSharedContentToken;
  try {
    if (sharedContentToken) {
      decodedSharedContentToken = decodeJWT<{ userSource: string }>(sharedContentToken);
    }
  } catch (e) {
    datadogRum.addError(e);
  }
  const { userSource: sharedContentUserSource } = decodedSharedContentToken || {};

  const [darkMode, setDarkMode] = useState<boolean>(DARK_MODE_MEDIA_QUERY.matches);
  const [theme, setTheme] = useState<Theme>(
    determineTheme({
      propsThemeId,
      userSource,
      sharedContentUserSource,
      darkMode,
    }),
  );

  // listen for dark mode if a theme was not passed in through props
  useEffect(() => {
    const darkModeListener = (e: MediaQueryListEvent) => {
      if (!propsThemeId) {
        setDarkMode(e.matches);
      }
    };

    setDarkMode(DARK_MODE_MEDIA_QUERY.matches);

    try {
      DARK_MODE_MEDIA_QUERY.addEventListener('change', darkModeListener);
    } catch (_) {
      try {
        // iOS < v14
        DARK_MODE_MEDIA_QUERY.addListener(darkModeListener);
      } catch (e) {
        datadogRum.addError(e);
      }
    }

    return () => {
      try {
        DARK_MODE_MEDIA_QUERY.removeEventListener('change', darkModeListener);
      } catch (_) {
        try {
          DARK_MODE_MEDIA_QUERY.removeListener(darkModeListener);
        } catch (e) {
          datadogRum.addError(e);
        }
      }
    };
  }, [propsThemeId]);

  // determine which theme
  useEffect(() => {
    const updatedTheme = determineTheme({
      propsThemeId,
      userSource,
      sharedContentUserSource,
      darkMode,
    });
    setPalmettoComponentsTheme(updatedTheme === Themes.palmetto ? 'light' : 'dark');
    setTheme(updatedTheme);
  }, [propsThemeId, userSource, sharedContentUserSource, isAuthenticated, darkMode, setPalmettoComponentsTheme]);

  // apply theme class
  useEffect(() => {
    if (!PlatformConfig.isChromatic) {
      // don't apply theme for Chromatic (see preview.tsx where themes are applied for Chromatic)
      Object.values(Themes).forEach((t: Theme) =>
        document.querySelector(THEME_ROOT_SELECTOR)?.classList.remove(t.cssClass),
      );
      document.querySelector(THEME_ROOT_SELECTOR)?.classList.add(theme.cssClass);
    }
  }, [theme.cssClass]);

  // return
  const value = React.useMemo(() => ({ theme, themeContainerRef, isLoading }), [isLoading, theme]);
  return (
    <div ref={onThemeContainerRefChange} className={`app ${theme.cssClass}`}>
      <ThemeContext.Provider value={value}>
        <React.Suspense fallback={null}>
          <theme.cssComponent />
        </React.Suspense>
        {children}
      </ThemeContext.Provider>
    </div>
  );
};

export const useThemeContext = (): ThemeContextShape => {
  return useContext(ThemeContext);
};

export default ThemeProvider;

import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, createContext } from 'react';
import { UseMutateFunction } from 'react-query';

import { LoginUser, LoginUserResponse } from 'api/login/login';

export interface AuthContext {
  login: UseMutateFunction<LoginUserResponse, AxiosError, LoginUser>;
  logout: () => void;
  isAuthLoading: boolean;
  loginError?: string;
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

const authContext = createContext<AuthContext>({
  login: () => null,
  logout: () => null,
  isAuthenticated: false,
  isAuthLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsAuthenticated: () => {},
});

export default authContext;

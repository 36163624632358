import classNames from 'classnames';
import React from 'react';

import Card from 'components/Card/Card';
import Link from 'components/Link/Link';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import useComponentTracking from 'hooks/useComponentTracking';

import styles from './LinksCard.module.scss';

export interface CardLink {
  href: string;
  trackingId: string;
  text: string;
}

export interface LinksCardProps {
  superscript?: string;
  trackingId: string;
  links: CardLink[];
  className?: string;
}

const LinksCard = (props: LinksCardProps) => {
  const { t } = useTranslation();
  const { superscript = t('linksCard.defaultSuperscript'), trackingId: cardTrackingId, links, className } = props;
  useComponentTracking(cardTrackingId);

  return (
    <Card className={classNames(styles.card, className)} semiTransparentBg>
      <h2 className={styles.superscript}>{superscript}</h2>
      {links.map(({ href, trackingId, text }) => (
        <Link
          className={styles.link}
          key={text}
          to={href}
          trackingId={`${cardTrackingId}-${trackingId}`}
          variant="standalone"
        >
          {text}
        </Link>
      ))}
    </Card>
  );
};

export default LinksCard;

import classNames from 'classnames';
import React from 'react';

import Button from 'components/Button/Button';

import styles from './HeaderButton.module.scss';

export interface Props {
  trackingId: string;
  align: 'left' | 'right' | 'none';
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  to?: string;
}

const HeaderButton: React.FC<Props> = (props: Props) => {
  const { trackingId, align, children, className, onClick, to } = props;
  return (
    <Button
      className={classNames(styles.button, className, {
        [styles.left]: align === 'left',
        [styles.right]: align === 'right',
      })}
      isNaked
      trackingId={trackingId}
      onClick={onClick}
      to={to}
    >
      {children}
    </Button>
  );
};

export default HeaderButton;

import React, { useContext } from 'react';
import { useLocation } from 'react-router';

import { useUser } from 'api/user/useUser';
import Logo from 'components/Logo/Logo';
import { ROUTES } from 'components/Routes/Routes';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';

import { currentPageContext } from 'components/_app/CurrentPageProvider/currentPageContext';
import styles from './Header.module.scss';
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu';
import ImpersonationBanner from './components/ImpersonationBanner/ImpersonationBanner';

export interface HeaderProps {
  forceImpersonationBanner?: boolean;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { forceImpersonationBanner = false } = props;

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();

  // accounts for routes with alphanumeric params
  // e.g. /properties/abc123/building/buildingType matches against /properties/:propertyId/building/:buildingId
  const createRouteRegex = (route: string) => {
    const pattern = route.replace(/\/:[^/]+/g, '/([a-zA-Z0-9]+)');
    return new RegExp(`^${pattern}$`);
  };

  const routeConfig = ROUTES.find((route) => {
    const routeRegex = createRouteRegex(String(route.path));
    return routeRegex.test(pathname);
  });

  const { pageTitle: currentPageTitle } = useContext(currentPageContext);

  const pageTitle = currentPageTitle || routeConfig?.headerProps?.pageTitle;

  const hideHeader = routeConfig?.headerProps?.hideHeader || !isAuthenticated;

  if (hideHeader) {
    return null;
  }

  return (
    <>
      <ImpersonationBanner forceImpersonationBanner={forceImpersonationBanner} />
      <header className={styles.header}>
        <HamburgerMenu align="none" />
        {pageTitle ? (
          <h1 className={styles.pageTitle}>{t(pageTitle)}</h1>
        ) : (
          <Logo placement="headerLeft" className={styles.logo} />
        )}
      </header>
    </>
  );
};

export default Header;

interface IFormatAddressParams {
  city?: string;
  stateCode?: string;
  streetAddress?: string;
  zip?: string;
}

export const PHONE_SUPPORT = 18553391831;
export const EMAIL_SUPPORT = 'help@palmetto.com';

export const PHONE_SUPPORT_SUNNOVA = 18667866682;
export const EMAIL_SUPPORT_SUNNOVA = 'customerservice@sunnova.com';

export const formatPhone = (num: number): string => {
  const numString = num.toString();
  if (numString.length === 11) {
    return numString.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '($2) $3-$4');
  }
  if (numString.length === 10) {
    return numString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return '';
};

export const formatAddressAsString = ({
  city = '',
  stateCode = '',
  streetAddress = '',
  zip = '',
}: IFormatAddressParams): string => {
  const partA = [streetAddress, city, stateCode].filter(Boolean).join(', ');
  return zip ? `${partA} ${zip}` : partA;
};

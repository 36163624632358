import { AxiosError } from 'axios';

// TODO: Implement translations - https://palmetto.atlassian.net/jira/software/projects/PA/boards/46/backlog?selectedIssue=PA-3576

const getAction = (method: string | undefined) => {
  switch (method?.toLocaleLowerCase()) {
    case 'post':
    case 'put':
      return 'submitting';
    case 'delete':
      return 'removing';
    default:
      return 'retrieving';
  }
};

export const getFriendlyError = (
  error: { config?: { method?: string } } | null,
  descriptor: string,
  action?: string,
): string | undefined => {
  if (error) {
    const { config } = error;
    return `Error ${action || getAction(config?.method)} ${descriptor}`;
  }
  return undefined;
};

export const getFriendlyQuestionnaireError = (
  error: AxiosError | null,
  descriptor: string,
  action?: string,
): string | undefined => {
  if (error) {
    if (error?.response?.data?.errors?.includes('Placeholder phone not found in context')) {
      return 'questionnaire.missingPhoneError';
    } else {
      return getFriendlyError(error, descriptor, action);
    }
  }
  return undefined;
};

export const getFriendlyLoginError = (error: AxiosError | null): string | undefined => {
  if (error) {
    switch (error.response?.status) {
      case 401:
        return 'login.steps.passwordOrOTP.toasts.incorrectPassword';
      default:
        return typeof error.response?.data === 'string'
          ? error.response?.data
          : error.response?.data.error_description || error.response?.statusText;
    }
  }
  return undefined;
};

export const getFriendlySignUpError = (error: AxiosError | null): string | undefined => {
  if (error) {
    if (error?.response?.data === 'Token expired') {
      return 'signup.errors.expiredToken';
    }
    if (error?.response?.status === 429) {
      // too many requests, display the actual error message
      return typeof error.response?.data === 'string'
        ? error.response?.data
        : error.response?.data.error_description || error.response?.statusText;
    }
    if (error?.response?.status === 409) {
      return 'signup.errors.existingAccount';
    }
    if (error?.response?.data?.type === 'auth0') {
      return error?.response?.data?.message;
    }
    return 'signup.errors.default';
  }
  return undefined;
};

export const getFriendlyUploadError = (error: AxiosError | null, descriptor: string): string | undefined => {
  if (error) {
    switch (error.response?.status) {
      case 413:
        return `The file(s) you are trying to upload are too large. Please try uploading smaller ${descriptor}(s).`;
      default:
        return getFriendlyError(error, descriptor);
    }
  }
  return undefined;
};

export const getFriendlyUpcomingInvoiceError = (
  error: AxiosError | null,
  descriptor: string,
): string | undefined => {
  if (error) {
    switch (error.response?.status) {
      case 404:
        return undefined;
      default:
        return getFriendlyError(error, descriptor);
    }
  }
  return undefined;
};

export const getFriendlyNetworkError = (error: AxiosError | null): string | undefined => {
  if (error) {
    const isNetworkError = error.message.includes('Network Error');
    if (isNetworkError) {
      return 'login.toasts.networkError';
    }
  }
  return undefined;
};

export const getFriendlyRateLimitError = (error: AxiosError | null): string | undefined => {
  if (error) {
    // CloudFlare returns 429 when rate limit exceeded but does not attach Access-Control-Allow-Origin header, resulting in CORS error
    // See more: https://community.cloudflare.com/t/cors-on-rate-limit-429/270010
    const isCorsError = error?.isAxiosError && error?.response === undefined;
    const isRateLimitExceededError = isCorsError || error.response?.status === 429;
    if (isRateLimitExceededError) {
      return 'login.toasts.rateLimitExceeded';
    }
  }
  return undefined;
};

export const getFriendlyIsExistingUserError = (error: AxiosError | null): string | undefined => {
  const localePrefix = 'login.steps.emailOrPhone.toasts';
  if (error) {
    const networkError = getFriendlyNetworkError(error);
    if (networkError) {
      return networkError;
    }
    const rateLimitExceededError = getFriendlyRateLimitError(error);
    if (rateLimitExceededError) {
      return rateLimitExceededError;
    }
    switch (error.response?.status) {
      case 409:
        return `${localePrefix}.multipleUsersFound`;
      case 404:
        return `${localePrefix}.userNotFound`;
      case 400:
        return `${localePrefix}.badRequest`;
      default:
        return 'login.toasts.defaultError';
    }
  }
  return undefined;
};

export const getFriendlySendOtpError = (error: AxiosError | null): string | undefined => {
  if (error) {
    const rateLimitExceededError = getFriendlyRateLimitError(error);
    if (rateLimitExceededError) {
      return rateLimitExceededError;
    }
    switch (error.response?.status) {
      case 400:
        return 'login.steps.selectOTPChannel.toasts.invalidIdentifier';
      default:
        return 'login.toasts.defaultError';
    }
  }
  return undefined;
};

export interface ApiResponse<T> {
  response: T;
  loading: boolean;
  error: boolean;
}

export enum TimePeriod {
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum EnergyType {
  production = 'production',
  consumption = 'consumption',
  gridConsumption = 'gridConsumption',
}

export enum EnergyInterval {
  day = 'day',
  hour = 'hour',
}

export interface EnergyReading {
  date: string; // LocalDateTime ("2020-08-01T00:00")
  value: number; // kWh
}

export interface EnergyReadingsByType {
  values: EnergyReading[];
  type: EnergyType;
  interval: EnergyInterval;
}

export interface EnergyResponse {
  energy: EnergyReadingsByType[];
}

export type EnergyTotalByType = {
  type: EnergyType;
  total: number;
};

export interface EnergyTotals {
  dailyTotal: number;
  monthlyTotal: number;
  yearlyTotal: number;
  total: number;
}

export interface SiteSummary {
  production?: EnergyTotals;
  consumption?: EnergyTotals;
}

import { noAuthRequest } from 'api/index';
import { storeAuthTokens, storeCredentials } from 'utils/auth';
import { LOGIN_REDIRECT_KEY } from '../../hooks/useLoginRedirect';

export interface LoginUser {
  code?: string;
  email?: string;
  phone?: string;
  password?: string;
  state?: string;
  otpIdentifier?: string;
  otp?: string;
  otpSource?: string;
}

export interface LoginUserResponse {
  token: string;
  signUp?: boolean;
  redirectUrl?: string;
}

export interface GetIsExistingUserResponse {
  hasAutoGeneratedPw: boolean;
}

export interface SignupUser {
  token: string;
  password?: string;
  channel: string;
}

export async function loginUser({
  email,
  phone,
  password,
  code,
  state,
  otpIdentifier,
  otp,
  otpSource,
}: LoginUser): Promise<LoginUserResponse> {
  const { data } = await noAuthRequest({
    method: 'post',
    url: '/login',
    data: { email, phone, password, code, state, otpIdentifier, otp, otpSource },
  });
  const { token, refreshToken, signUp } = data;
  storeAuthTokens(token, refreshToken);
  await storeCredentials(email || '', password || '');
  return { token, signUp };
}

export async function postResetPassword(email: string): Promise<void> {
  await noAuthRequest({ method: 'post', url: '/login/reset', data: { email } });
}

export async function signup({ token, password, channel }: SignupUser): Promise<{ redirectUrl?: string }> {
  const { data } = await noAuthRequest({
    method: 'post',
    url: '/login/signup',
    data: { token, channel, password },
  });
  const { token: authToken, refreshToken, redirectUrl } = data;
  storeAuthTokens(authToken, refreshToken);
  if (redirectUrl) {
    window.localStorage.setItem(LOGIN_REDIRECT_KEY, redirectUrl);
  }
  return { redirectUrl };
}

export type ExistingUserReason = 'EXISTING_EMAIL' | 'EXISTING_SABAL_ACCOUNT';

export interface CheckUserExistsResponse {
  exists: boolean;
  reason?: ExistingUserReason;
  phoneNumberLastFour?: string;
}
export async function checkUserExists(token: string): Promise<CheckUserExistsResponse> {
  const { data } = await noAuthRequest({ url: '/login/signup/check', params: { token } });
  return data;
}

export async function getIsExistingUser(emailOrPhone: string): Promise<GetIsExistingUserResponse> {
  const result = await noAuthRequest({ url: '/login/users', params: { identifier: emailOrPhone } });
  return result.data;
}

export interface OtpOptionsRequest {
  phone?: string;
  email?: string;
}

export interface OtpOptionsResponse {
  phone?: string;
  email?: string;
}

export async function getOtpOptions({ phone, email }: OtpOptionsRequest): Promise<OtpOptionsResponse> {
  const result = await noAuthRequest({ url: '/login/otp-options', params: { phone, email } });
  return result.data;
}

export interface SendOtpRequest {
  destination: string;
  identifier: string;
}

export async function sendOtp({ destination, identifier }: SendOtpRequest): Promise<void> {
  await noAuthRequest({
    method: 'post',
    url: '/login/otp',
    data: { destination, identifier },
  });
}

/**
 * Note: ResizeObserver does not always trigger on window resize in Storybook
 */

import throttle from 'lodash/throttle';
import React, { useMemo } from 'react';

export const useResizeObserver = (callback: () => void, throttleMilliseconds = 0) => {
  const onResize = useMemo(
    () =>
      throttleMilliseconds > 0
        ? throttle(() => {
            setTimeout(() => callback(), 0);
          }, throttleMilliseconds)
        : () => setTimeout(() => callback(), 0),
    [throttleMilliseconds, callback],
  );

  const observer = React.useRef(typeof ResizeObserver === 'function' ? new ResizeObserver(onResize) : undefined);

  const startObserving = () => {
    if (observer.current) {
      observer.current.observe(document.body);
    }
  };

  const endObserving = () => {
    if (observer.current) {
      observer.current.unobserve(document.body);
    }
  };

  React.useEffect(() => {
    onResize();
    startObserving();
    return () => {
      // cleanup
      endObserving();
    };
  }, [onResize]);
};

/**
 * Note: ResizeObserver does not always trigger on window resize in Storybook
 */

import throttle from 'lodash/throttle';
import React, { useMemo, useRef, useState } from 'react';

const RETRY_INTERVAL = 100;
const MAX_RETRIES = 10;

export type Dimensions = {
  width: number; // the width of the element including padding
  height: number; // the height of the element including padding
  contentWidth: number; // the width of the element minus padding, border, and margin
  contentHeight: number; // the height of the element minus padding, border, and margin
};

const getDimensions = (ref: React.RefObject<HTMLElement>) => {
  const width = ref.current?.offsetWidth || 0;
  const height = ref.current?.offsetHeight || 0;

  let contentWidth = 0;
  let contentHeight = 0;

  const computedStyle = ref.current && getComputedStyle(ref.current);
  if (computedStyle) {
    contentWidth = Math.max(
      0,
      width - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight),
    );
    contentHeight = Math.max(
      0,
      height - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom),
    );
  }

  return {
    width,
    height,
    contentWidth,
    contentHeight,
  };
};

export const useDimensions = (ref: React.RefObject<HTMLElement>, throttleMilliseconds = 0): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>(getDimensions(ref));
  const retries = useRef<number>(0);

  const onResize = useMemo(
    () =>
      throttleMilliseconds > 0
        ? throttle(() => {
            setTimeout(() => setDimensions(getDimensions(ref)), 0);
          }, throttleMilliseconds)
        : () => setTimeout(() => setDimensions(getDimensions(ref)), 0),
    [ref, throttleMilliseconds],
  );
  const observer = React.useRef(typeof ResizeObserver === 'function' ? new ResizeObserver(onResize) : undefined);
  const startObserving = () => {
    if (observer.current && ref.current) {
      observer.current.observe(ref.current);
    }
  };
  const endObserving = () => {
    if (observer.current && ref.current) {
      observer.current.unobserve(ref.current);
    }
  };

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (ref.current?.isConnected) {
      // element is already connected
      startObserving();
    } else {
      // wait for element to be connected to the document
      intervalId = setInterval(() => {
        retries.current += 1;
        if (ref.current?.isConnected || retries.current >= MAX_RETRIES) {
          clearInterval(intervalId);
          setDimensions(getDimensions(ref));
          startObserving();
        }
      }, RETRY_INTERVAL);
    }
    return () => {
      // cleanup
      endObserving();
      clearInterval(intervalId);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return dimensions;
};

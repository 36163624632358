import classNames from 'classnames';
import React from 'react';

import styles from './NotificationBadge.module.scss';

interface BadgeProps {
  variant?: 'float' | 'inline';
  content?: string;
}

export const NotificationBadge: React.FC<BadgeProps> = ({ variant = 'inline', content = '' }) => {
  const badgeClasses = classNames(styles.badge, {
    [styles.float]: variant === 'float',
    [styles.inline]: variant === 'inline',
  });
  return (
    <div className={classNames(styles[variant])}>
      <div className={badgeClasses} data-testid="notification-badge">
        {content}
      </div>
    </div>
  );
};

export default NotificationBadge;

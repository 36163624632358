export type Dimensions = {
  width: number; // the width of the element including padding
  height: number; // the height of the element including padding
  contentWidth: number; // the width of the element minus padding, border, and margin
  contentHeight: number; // the height of the element minus padding, border, and margin
  left: number; // the position of the element relative to the left edge of the page
  top: number; // the position of the element relative to the top edge of the page
};

export const getDimensions = (element: HTMLElement): Dimensions => {
  const width = element.offsetWidth || 0;
  const height = element.offsetHeight || 0;

  let contentWidth = 0;
  let contentHeight = 0;

  const computedStyle = getComputedStyle(element);
  if (computedStyle) {
    contentWidth = Math.max(
      0,
      width - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight),
    );
    contentHeight = Math.max(
      0,
      height - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom),
    );
  }

  const rect = element.getBoundingClientRect();
  const left = rect.left + window.scrollX;
  const top = rect.top + window.scrollY;

  return {
    width,
    height,
    contentWidth,
    contentHeight,
    left,
    top,
  };
};

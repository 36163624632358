import { Box } from '@palmetto/palmetto-components';
import React from 'react';

import { termsAndConditionsName } from 'api/customerTerms/customerTerms';
import useCustomerTerms from 'api/customerTerms/useCustomerTerms';
import useTermsAgreements from 'api/customerTerms/useTermsAgreement';
import Button from 'components/Button/Button';
import LoadingContent from 'components/LoadingContent/LoadingContent';
import Modal from 'components/Modal/Modal';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';

import styles from './CustomerTermsModal.module.scss';

export interface CustomerTermsModalProps {
  disableAccept?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

const CustomerTermsModal: React.FC<CustomerTermsModalProps> = (props: CustomerTermsModalProps) => {
  const { t } = useTranslation();
  const { disableAccept, isOpen, setIsOpen } = props;
  const { data: customerTerms, isLoading: isCustomerTermsLoading } = useCustomerTerms(termsAndConditionsName);
  const { createTermsAgreement, isPostLoading, showTermsAgreement } = useTermsAgreements(
    termsAndConditionsName,
    customerTerms?._id,
  );
  const localePrefix = 'termsAndConditions';

  const handleAcceptButtonClick = () => createTermsAgreement();

  return (
    <Modal
      id="modal-customer-terms"
      header={t(`${localePrefix}.header`)}
      footer={
        <Box direction="row" justifyContent="flex-end" width="100%">
          {disableAccept ? (
            <Button
              fullWidth={false}
              variant="primary"
              onClick={() => setIsOpen && setIsOpen(false)}
              isLoading={isPostLoading}
              trackingId="customer-terms-modal-close"
            >
              {t('common.close')}
            </Button>
          ) : (
            <Button
              fullWidth={false}
              variant="primary"
              onClick={handleAcceptButtonClick}
              isLoading={isPostLoading}
              trackingId="customer-terms-modal-accept"
            >
              {t('common.accept')}
            </Button>
          )}
        </Box>
      }
      fullScreenMobile
      isOpen={Boolean(showTermsAgreement || isOpen)}
    >
      {isCustomerTermsLoading ? (
        <LoadingContent />
      ) : (
        <div>
          {/* TODO: Remove once initialFocusRef works https://trello.com/c/0mD7jgV3 */}
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/control-has-associated-label */}
          <a href="#" />
          <div
            className={styles.termsConditionsContent}
            dangerouslySetInnerHTML={{ __html: customerTerms?.content || '' }}
          />
        </div>
      )}
    </Modal>
  );
};

export default CustomerTermsModal;

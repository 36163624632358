import { useMemo, useState } from 'react';
import { currentPageContext } from './currentPageContext';

export interface Props {
  children?: React.ReactNode;
}

export const CurrentPageProvider = ({ children }: Props) => {
  const [pageTitle, setPageTitle] = useState('');

  const value = useMemo(
    () => ({
      pageTitle,
      setPageTitle,
    }),
    [pageTitle],
  );

  return <currentPageContext.Provider value={value}>{children}</currentPageContext.Provider>;
};

import { Alert, AlertProps } from '@palmetto/palmetto-components';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Message.module.scss';

export type Color = 'blue' | 'green' | 'yellow' | 'red';
type Variant = 'info' | 'success' | 'warning' | 'danger';

export interface Props extends AlertProps {
  children?: ReactNode;
  color?: Color;
  onClose?: () => void;
}

const colorDictionary: Record<Color, Variant> = {
  blue: 'info',
  green: 'success',
  yellow: 'warning',
  red: 'danger',
};

const Message: React.FC<Props> = (props: Props) => {
  const { className, color, variant: variantFromProps, children, onClose, ...restProps } = props;
  const variant = color ? colorDictionary[color] : variantFromProps;
  return (
    <Alert
      {...restProps}
      className={classNames(styles.msg, className, { [styles.hasMessage]: Boolean(children) })}
      variant={variant}
      message={children}
      onClose={onClose}
      isClosable={Boolean(onClose)}
    />
  );
};

export default Message;
